<template>
  <div class="container">
    <memlist-modal size="lg" ref="edit-form" :visible="show_modal" @close="show_modal = false" hide-footer>
      <div>
        <h4>{{ form_data.id ? 'Redigera Enkät' : 'Skapa Enkät' }}</h4>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input 
              title="Namn på enkäten" 
              id="name" 
              name="name"
              layout="vertical"
              v-model="form_data.name" 
              :placeholder="'Namn'"
            />
          </div>
        </div>

        <!--<div class="ml-row mt-4">
          <div class="ml-col">
            <p style="font-weight: bold;">Tillfrågad typ av person</p>
            <div class="checkbox-group">
              <memlist-checkbox text="Medlem" v-model="form_data.is_member" />
              <memlist-checkbox text="Användare" v-model="form_data.is_user" />
              <memlist-checkbox text="Publik" v-model="form_data.is_public" />
            </div>
          </div>
        </div>-->

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-textarea 
              layout="vertical" 
              title="Beskrivning" 
              id="descr" 
              name="descr" 
              v-model="form_data.descr" 
              :placeholder="'Beskrivning'"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-checkbox 
              text="Skicka svar till administratör via e-post" 
              v-model="form_data.is_email_active" 
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <h4>Frågor</h4>
            <QuestionFormQuestionItemTable
              v-if="form_data.id"
              :question_form_id="form_data.id"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <p style="font-weight: bold;">Länk till enkäten</p>
            <p v-if="form_data.linkstr">
              <a :href="`${linkstr}`" target="_blank">
                {{ linkstr }}
              </a>
            </p>
            <p v-else>Ingen länk tillgänglig</p>
          </div>
        </div>
      </div>
      
      <RightModalSaveAndCloseButtons 
        :text="$t('COMMON.SAVE')"
        :spin="saving"  
        @clicked="save_group" 
        @close="show_modal = false" 
        ref="saveButton" 
      />
    </memlist-modal>
  </div>
</template>

<script>
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import QuestionFormQuestionItemTable from '@/view/pages/ml/questions/QuestionFormQuestionItemTable.vue';
import { get_domain_url } from '@/core/services/fileDownload';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'QuestionFormEditorModal',
  components: {
    RightModalSaveAndCloseButtons,
    QuestionFormQuestionItemTable
  },
  computed: {
    linkstr() {
      return this.form_data.linkstr ? `${get_domain_url()}/answer/${this.form_data.linkstr}` : '';
    },
    ...mapGetters(['currentCompanyId', 'customer']),
  
  },
  props: {
    question_form_id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    question_form_id: {
      handler(newVal) {

        if (this.question_form_id) {
          this.fetch_form(this.question_form_id);
        }
        else {
          this.form_data = {
            
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      show_modal: false,
      saving: false,
      form_data: {
        id: null,
        name: '',
        customer_id: null,
        company_id: null,
        created_at: '',
        descr: '',
        is_member: false,
        is_user: false,
        is_public: false,
        is_email_active: false,
        linkstr: '',
        deleted: false,
        items: []
      }
    };
  },
  methods: {
    show() {
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },

    async fetch_form(id) {
      const res = await axios.get(`/question_form/${id}`);

      if (res.status === 200) {
        this.form_data = res.data;
      }
    },

    async save_group() {
      this.saving = true;
      try {
        if (this.form_data.id) {
          // UPDATE EXISTING FORM
          await axios.put(`/question_form/${this.form_data.id}`, this.form_data);
          this.$emit('updated', this.form_data);
        } else {

          this.form_data.company_id = this.currentCompanyId;
          this.form_data.customer_id = this.customer.id;

          // CREATE NEW FORM
          const response = await axios.post('/question_form', this.form_data);
          this.$emit('created', response.data);
          this.form_data.id = response.data.id;
        }
        this.hide();
      } catch (error) {
        console.error('Error saving form:', error);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";

.container {
  padding: 20px;
}
.checkbox-group {
  display: flex;
  gap: 10px;
}
</style>
