<template>
  <div>
    
    <QuestionFormAnsweredModal
      ref="question-form-answered-modal"
      :answer_id="selected_answer_id"
    />

    <b-row class="mt-3">
      <b-col cols="4">
        <div class="bottom-alignment">
          <strong>Antal rader:</strong> {{ total_rows }}
        </div>
      </b-col>
      <b-col cols="8">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[10, 50, 100]"
            v-model="per_page"
          />
          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <div v-if="loading" class="d-flex justify-content-center mb-8 mt-8">
      <memlist-spinner />
    </div>
    <b-table
      style="width: 100%; table-layout: fixed;"
      ref="questions-answered-table"
      class="table-striped"
      :filter="filters"
      :fields="headers"
      :items="ajax_pagination"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
    >
      <template #cell(actions)="data">
        <memlist-table-button class="mr-3" @click="edit_item(data.item)" svg="/assets/svg/Write.svg" :tooltip="'Redigera'" />
        <memlist-table-button class="mr-3" @click="delete_item(data.item.id)" svg="/assets/svg/Trash.svg" :tooltip="'Radera'" />
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import QuestionFormAnsweredModal from '@/view/pages/ml/questions/QuestionFormAnsweredModal.vue';


export default {
  name: 'QuestionsAnsweredTable',
  components: {
    QuestionFormAnsweredModal
  },
  data() {
    return {
      loading: false,
      filters: {},
      total_rows: 0,
      current_page: 1,
      per_page: 10,
      search: null,
      selected_answer_id: null
    };
  },
  computed: {
    headers() {
      return [
        { label: 'Enkät', sortable: true, key: 'question_group', tdClass: 'td-overflow', thClass: 'td-overflow', formatter: (_, __, item) => item.question_form.name },
        { label: 'Besvarad av', sortable: true, key: 'answered_by', tdClass: 'td-overflow', thClass: 'td-overflow', formatter: (_, __, item) => item.member ? (item.member.firstname + ' ' + item.member.lastname) : item.email },
        { label: 'Besvarad tid', sortable: true, key: 'answered_at', tdClass: 'td-overflow', thClass: 'td-overflow', formatter: (_, __, item) => dayjs(item.created_at).format('YYYY-MM-DD') },
        { label: this.$t('COMMON.ACTIONS'), key: 'actions', sortable: false, }
      ];
    }
  },
  methods: {

    edit_item(item) {
      this.selected_answer_id = item.id;
      this.$refs['question-form-answered-modal'].show();
    },
    
    async get_count() {
      const res = await axios.get(`/question_form/answers/count`);

      this.total_rows = res.data.count;
    },

    async get_pagination(callback) {
      this.loading = true;

      //const options = this.get_options();

      try {
        const res = await axios.get(`/question_form/answers?page=${this.current_page}&limit=${this.per_page}`);

        if (res.status === 200) {
          var arr = res.data;
          this.loading = false;

          if (callback) {
            callback(arr || []);
            return;
          }
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        console.error('pagination get error', err);
      }

    },

    ajax_pagination: function(ctx, callback) {
      const vm = this;

      let params = {
        pageNo: ctx.currentPage,
        pageSize: ctx.perPage,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_count();
      this.get_pagination(callback);

      return null;
    },


    refresh() {
      this.$refs['questions-answered-table'].refresh();
    }
  }
};
</script>

<style scoped>
.container {
  padding: 20px;
}
</style>
