<template>
  <div>
    <div class="tabs mt-2">
      <ul class="tab-list">
        <li :class="{ active: activeTab === 0 }" @click="activeTab = 0">{{ $t('QUESTIONS.OVERVIEW') }}</li>
        <li :class="{ active: activeTab === 1 }" @click="activeTab = 1">{{ $t('QUESTIONS.ANSWERS') }}</li>
      </ul>
      <div class="tab-content">
        
        <div v-show="activeTab === 0">
          <QuestionFormTable />
        </div>
        <div v-show="activeTab === 1">
          <QuestionFormAnsweredTable />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

import QuestionFormTable from '@/view/pages/ml/questions/QuestionFormTable.vue';
import QuestionFormAnsweredTable from '@/view/pages/ml/questions/QuestionFormAnsweredTable.vue';


export default {
  name: 'QuestionsPage',
  components: {
    draggable,
    QuestionFormTable,
    QuestionFormAnsweredTable,
  },
  data() {
    return {
      activeTab: 0,
      
    };
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";

</style>
