<template>
  <div class="container">
    <memlist-modal size="lg" ref="edit-form" :visible="show_modal" @close="show_modal = false" hide-footer>
      <div>
        <h4>{{ form_data.id ? 'Redigera alternativ' : 'Skapa alternativ' }}</h4>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input 
              title="Alternativets namn" 
              id="name" 
              name="name"
              layout="vertical"
              v-model="form_data.name" 
              :placeholder="'Alternativets namn'"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input 
              title="Värde (valfritt)" 
              id="value" 
              name="value"
              layout="vertical"
              v-model="form_data.value" 
              :placeholder="'Värde om annat än namn'"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-textarea 
              layout="vertical" 
              title="Beskrivning (valfritt)" 
              id="descr" 
              name="descr" 
              v-model="form_data.descr" 
              :placeholder="'Beskrivning'"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-checkbox text="Låser upp textfält" v-model="form_data.unlock_text" />
          </div>
        </div>
      </div>

      <RightModalSaveAndCloseButtons 
        :text="$t('COMMON.SAVE')"
        :spin="saving"  
        @clicked="save_option" 
        @close="show_modal = false" 
        ref="saveButton" 
      />
    </memlist-modal>
  </div>
</template>

<script>
import axios from 'axios';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {
  name: 'QuestionFormQuestionItemOptionEditorModal',
  components: {
    RightModalSaveAndCloseButtons
  },
  data() {
    return {
      show_modal: false,
      saving: false,
      form_data: {
        id: null,
        name: '',
        value: '',
        descr: '',
        unlock_text: false,
        form_item_id: null // Set when editing or creating an option
      }
    };
  },
  props: {
    question_form_id: {
      type: Number,
      required: false,
      default: null,
    },
    form_item_id: {
      type: Number,
      required: false,
      default: null,
    },
    option_id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    option_id: {
      handler(newVal) {

        if (this.option_id) {
          this.fetch_item(this.option_id);
        }
        else {
          this.form_data = {
            
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    show() {
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },

    reset() {
      this.form_data = {
        form_item_id: this.form_item_id
      };
    },

    async fetch_item(id) {
      const res = await axios.get(`/question_form/item/option/${id}`);

      if (res.status === 200) {
        this.form_data = res.data;
      }

    },

    async save_option() {
      this.saving = true;
      try {
        if (this.form_data.id) {
          // UPDATE EXISTING OPTION
          await axios.put(`/question_form/item/option/${this.form_data.id}`, this.form_data);
          this.$emit('updated', this.form_data);
        } else {
          // CREATE NEW OPTION
          const response = await axios.post(`/question_form/item/${this.form_item_id}/option`, this.form_data);
          this.$emit('created', response.data);
          this.form_data.id = response.data.id;
        }
        this.hide();
      } catch (error) {
        console.error('Error saving option:', error);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";

.container {
  padding: 20px;
}
.checkbox-group {
  display: flex;
  gap: 10px;
}
</style>
