<template>
  <div class="container">
    <memlist-modal size="lg" ref="edit-form" :visible="show_modal" @close="show_modal = false" hide-footer>
      <div>
        <h4>{{ form_data.id ? 'Redigera fråga' : 'Skapa fråga' }}</h4>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input 
              title="Frågeställning" 
              id="name" 
              name="name"
              layout="vertical"
              v-model="form_data.name" 
              :placeholder="'Frågeställning'"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-select-input2
              id="type"
              name="type"
              :title="'Svarstyp'"
              layout="vertical"
              validation_type="TEXT"
              v-model="form_data.type"
              :items="type_options"
              :required="true"
              :invalid_text="''"
              :info_text="''"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-textarea 
              layout="vertical" 
              title="Frivillig utförlig beskrivning av frågan" 
              id="descr" 
              name="descr" 
              v-model="form_data.descr" 
              :placeholder="'Beskrivning'"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <h4>Svarsalternativ</h4>
            <p v-if="!form_data.id">Spara för att lägga till</p>
            <QuestionFormQuestionItemOptionTable
              v-else
              :item_id="form_data.id"
              @updated="updated_option"
              @created="created_option"
            />
          </div>
        </div>

        <!--<div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-checkbox text="Obligatoriskt" v-model="form_data.is_mandatory" />
          </div>
        </div>-->
      </div>

      <RightModalSaveAndCloseButtons 
        :text="$t('COMMON.SAVE')"
        :spin="saving"  
        @clicked="save_question" 
        @close="show_modal = false" 
        ref="saveButton" 
      />
    </memlist-modal>
  </div>
</template>

<script>
import axios from 'axios';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import QuestionFormQuestionItemOptionTable from '@/view/pages/ml/questions/QuestionFormQuestionItemOptionTable.vue';

export default {
  name: 'QuestionFormQuestionItemEditorModal',
  components: {
    RightModalSaveAndCloseButtons,
    QuestionFormQuestionItemOptionTable
  },
  data() {
    return {
      show_modal: false,
      saving: false,
      form_data: {
        type: 'TEXT', // Default type
        is_mandatory: false,
      },
      options: []
    };
  },
  props: {
    question_form_id: {
      type: Number,
      required: false,
      default: null,
    },
    item_id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    item_id: {
      handler(newVal) {

        if (this.item_id) {
          this.fetch_item(this.item_id);
        }
        else {
          this.form_data = {
            question_form_id: this.question_form_id,
            type: 'RADIO'
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    type_options() {
      return [
        { text: 'Enkelval', value: 'RADIO' },
        { text: 'Flerval', value: 'CHECKBOX' },
        { text: 'Text', value: 'TEXT' },
        //{ text: 'Filuppladdning', value: 'FILE' },
        //{ text: 'Dropdown', value: 'SELECT' },
        { text: 'Datum', value: 'DATE' }
      ];
    }
  },
  methods: {
    updated_option(item) {
      const index = this.options.findIndex(i => i.id === item.id);

      if (index >= 0) {
        this.options[index] = item;
        this.options = [ ...this.options ];
      }
    },
    created_option(item) {
      this.options.push(item);
    },

    show() {
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },

    reset() {
      this.form_data = {
        question_form_id: this.question_form_id
      };
    },

    async fetch_item(id) {
      const res = await axios.get(`/question_form/item/${id}`);

      if (res.status === 200) {
        this.form_data = res.data;

        if (this.form_data.options) {
          this.options = [ ...this.form_data.options ];
        }
      }

    },

    async save_question() {
      this.saving = true;
      try {
        if (this.form_data.id) {
          // UPDATE EXISTING QUESTION
          await axios.put(`/question_form/item/${this.form_data.id}`, this.form_data);
          this.$emit('updated', this.form_data);
        } else {
          // CREATE NEW QUESTION
          const response = await axios.post(`/question_form/${this.question_form_id}/item`, this.form_data);

          this.$emit('created', response.data);
          this.form_data.id = response.data.id;
        }
        this.hide();
      } catch (error) {
        console.error('Error saving question:', error);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";

.container {
  padding: 20px;
}
.checkbox-group {
  display: flex;
  gap: 10px;
}
</style>
