<template>
  <div>

    <QuestionFormEditorModal 
      ref="edit-question-group-modal"
      :question_form_id="selected_question_form_id"
      @created="created"
      @updated="updated"
      @cancelled="cancelled"
    />

    <div class="px-4">
      <b-row>
        <b-col>
          <a
            href="#"
            class="btn btn-primary font-weight-bolder font-size-sm mb-2"
            style="float: right;"
            @click.prevent="create_question_group"
          >
            <i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>
            Skapa enkät
          </a>
        </b-col>
      </b-row>
    </div>
    
    <b-row class="mt-3">
      <b-col cols="4">
        <div class="bottom-alignment">
          <strong>Antal rader:</strong> {{ total_rows }}
        </div>
      </b-col>
      <b-col cols="8">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[10, 50, 100]"
            v-model="per_page"
          />
          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <b-table
      style="width: 100%; table-layout: fixed;"
      ref="question-group-table"
      class="table-striped"
      :filter="filters"
      :fields="headers"
      :items="items"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
    >
      <template #cell(actions)="data">
        <memlist-table-button class="mr-3" @click="edit_group(data.item)" svg="/assets/svg/Write.svg" :tooltip="'Redigera'" />
        <memlist-table-button class="mr-3" @click="delete_group(data.item.id)" svg="/assets/svg/Trash.svg" :tooltip="'Radera'" />
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import QuestionFormEditorModal from '@/view/pages/ml/questions/QuestionFormEditorModal.vue';

export default {
  name: 'QuestionFormTable',
  components: {
    QuestionFormEditorModal
  },
  data() {
    return {
      selected_question_form_id: null,
      items: [],
      filters: {},
      total_rows: 0,
      current_page: 1,
      per_page: 10,
      search: null
    };
  },
  computed: {
    headers() {
      return [
        { label: 'ID', sortable: true, key: 'id', tdClass: 'td-overflow', thClass: 'td-overflow' },
        { label: 'Namn', sortable: true, key: 'name', tdClass: 'td-overflow', thClass: 'td-overflow' },
        { label: 'Skapad', sortable: true, key: 'created_at', tdClass: 'td-overflow', thClass: 'td-overflow', formatter: (_, __, item) => dayjs(item.created_at).format('YYYY-MM-DD') },
        { label: this.$t('COMMON.ACTIONS'), key: 'actions', sortable: false, }
      ];
    }
  },
  methods: {
    async fetch_items() {
      try {
        const response = await axios.get(`/question_form`);
        this.items = response.data;
        this.total_rows = response.data.length;
      } catch (error) {
        console.error("Error fetching question forms:", error);
      }
    },

    created(item) {
      this.items.push(item);
      this.total_rows = this.items.length;
    },

    updated(item) {
      const index = this.items.findIndex(i => i.id === item.id);

      if (index !== -1) {
        this.items[index] = item;
      }

      this.items = [ ...this.items ];

      this.selected_question_form_id = null;
    },

    cancelled() {
      this.selected_question_form_id = null;
    },

    create_question_group() {
      this.selected_question_form_id = null;
      this.$refs['edit-question-group-modal'].show();
    },

    edit_group(item) {
      this.selected_question_form_id = item.id;

      this.$refs['edit-question-group-modal'].show();
    },

    async delete_group(id) {
      if (!confirm('Är du säker på att du vill radera denna enkät?')) return;

      try {
        await axios.delete(`/question_form/${id}`);
        
        this.items = this.items.filter(item => item.id !== id);
        this.total_rows = this.items.length;
      } catch (error) {
        console.error("Error deleting question form:", error);
      }
    },

    refresh() {
      this.fetch_items();
    }
  },
  mounted() {
    this.fetch_items();
  }
};
</script>

<style scoped>
.container {
  padding: 20px;
}
</style>
