<template>
  <div>

    <a
      href="#"
      class="btn btn-primary font-weight-bolder font-size-sm mb-2"
      style="float: right;"
      @click.prevent="create_item"
    >
      <i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>
      Skapa fråga
    </a>


    <div v-if="loading" class="d-flex justify-content-center mb-8 mt-8">
      <memlist-spinner />
    </div>
    
    <b-table
      v-else
      style="width: 100%; table-layout: fixed;"
      ref="question-group-table"
      class="table-striped"
      :filter="filters"
      :fields="headers"
      :items="items"
      head-variant="light"
    >
      <template #cell(actions)="data">

        <memlist-table-button class="mr-3" @click="move_up(data.item)" icon="fa fa-arrow-up"  :tooltip="'Flytta upp'" />
        <memlist-table-button class="mr-3" @click="move_down(data.item)" icon="fa fa-arrow-down" :tooltip="'Flytta ned'" />

        <memlist-table-button class="mr-3" @click="edit_item(data.item)" svg="/assets/svg/Write.svg" :tooltip="'Redigera'" />
        <memlist-table-button class="mr-3" @click="delete_item(data.item.id)" svg="/assets/svg/Trash.svg" :tooltip="'Radera'" />
      </template>
    </b-table>

    <QuestionFormQuestionItemEditorModal
      ref="question-item-editor-modal"
      :item_id="selected_item_id"
      :question_form_id="question_form_id"
      @created="created"
      @updated="updated"
      @cancelled="cancelled"
    />

  </div>
</template>

<script>
import axios from 'axios';
import QuestionFormQuestionItemEditorModal from '@/view/pages/ml/questions/QuestionFormQuestionItemEditorModal.vue';

export default {
  name: 'QuestionFormQuestionItemTable',
  components: {
    QuestionFormQuestionItemEditorModal
  },
  props: ['question_form_id'],
  watch: {
    question_form_id: {
      handler(newVal) {

        if (this.question_form_id) {
          this.fetch_items(this.question_form_id);
        }
        else {
          this.form_data = {
            
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      loading: true,
      selected_item_id: null,
      items: [],
      filters: {},
      total_rows: 0,
      current_page: 1,
      per_page: 10,
      search: null
    };
  },
  computed: {
    headers() {
      return [
        { label: 'ID', sortable: true, key: 'id', tdClass: 'td-short2', thClass: 'td-short2' },
        { label: 'Namn', sortable: true, key: 'name', tdClass: 'td-overflow', thClass: 'td-overflow' },
        { label: this.$t('COMMON.ACTIONS'), key: 'actions', sortable: false, }
      ];
    }
  },
  methods: {
    async move_up(item) {
      const res = await axios.put(`/question_form/item/move/${item.id}/up`);

      if (res.status === 204) {
        this.fetch_items();
      }
      
    },
    async move_down(item) {
      const res = await axios.put(`/question_form/item/move/${item.id}/down`);

      if (res.status === 204) {
        this.fetch_items();
      }
      
    },
    async fetch_items() {
      try {
        if (!this.question_form_id) {
          return;
        }

        this.loading = true;

        const response = await axios.get(`/question_form/${this.question_form_id}/items`);
        
        this.items = response.data;
      } catch (error) {
        console.error("Error fetching items:", error);
      }

      this.loading = false;
    },

    edit_item(item) {
      this.selected_item_id = item.id;
      this.$refs['question-item-editor-modal'].show();
    },

    async delete_item(item_id) {
      if (!confirm('Är du säker på att du vill radera denna fråga?')) return;

      try {
        await axios.delete(`/question_form/item/${item_id}`);
        this.items = this.items.filter(item => item.id !== item_id);
        this.$emit('deleted', item_id);
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    },

    created(item) {
      this.items.push(item);
      this.$emit('created', item);
    },

    updated(item) {
      const index = this.items.findIndex(i => i.id === item.id);

      if (index !== -1) {
        this.items[index] = item;
      }
      
      this.$emit('updated', item);
    },

    cancelled() {
      this.selected_item_id = null;
    },

    create_item() {
      this.selected_item_id = null;
      this.$refs['question-item-editor-modal'].reset();
      this.$refs['question-item-editor-modal'].show();
    },

    refresh() {
      this.fetch_items();
    }
  },
  mounted() {
    this.fetch_items();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_table.scss";

.container {
  padding: 20px;
}
</style>
