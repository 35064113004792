<template>
  <div class="container">
    <memlist-modal size="lg" ref="edit-form" :visible="show_modal" @close="show_modal = false" hide-footer>
      <div v-if="form_data">
        <h4>Svar på enkät {{ formatted_date }}</h4>

        <div v-for="(item, index) in form_data.answers" :key="index" class="question-wrapper">
          <div class="ml-row mt-4">
            <div class="ml-col">
              <p style="font-weight: bold;">{{ item.text }}</p>
              <p>{{ item.value }}</p>
            </div>
          </div>
        </div>
        
        <RightModalSaveAndCloseButtons 
          :text="$t('COMMON.SAVE')"
          :spin="saving"
          @clicked="show_modal = false" 
          @close="show_modal = false" 
          ref="saveButton" 
        />
      </div>
    </memlist-modal>
  </div>
</template>

<script>
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import { get_domain_url } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';


export default {
  name: 'QuestionFormAnsweredModal',
  components: {
    RightModalSaveAndCloseButtons,
  },
  computed: {
    linkstr() {
      return this.form_data.question_form.linkstr ? `${get_domain_url()}/answer/${this.form_data.question_form.linkstr}` : '';
    },
    ...mapGetters(['currentCompanyId', 'customer']),
    formatted_date() {
      return dayjs(this.form_data.created_at).format('YYYY-MM-DD HH:mm')
    }
  },
  props: {
    answer_id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    answer_id: {
      handler(newVal) {

        if (this.answer_id) {
          this.fetch_form(this.answer_id);
        }
        else {
          this.form_data = {
            
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      show_modal: false,
      saving: false,
      form_data: {}
    };
  },
  methods: {
    show() {
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },

    async fetch_form(id) {
      const res = await axios.get(`/question_form/answer/${id}`);

      if (res.status === 200) {
        this.form_data = res.data;
      }
    },

  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";

.container {
  padding: 20px;
}
.checkbox-group {
  display: flex;
  gap: 10px;
}
</style>
