<template>
  <div>

    <a
      href="#"
      class="btn btn-primary font-weight-bolder font-size-sm mb-2"
      style="float: right;"
      @click.prevent="create_option"
    >
      <i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>
      Skapa svarsalternativ
    </a>
    
    <div v-if="loading" class="d-flex justify-content-center mb-8 mt-8">
      <memlist-spinner />
    </div>
    <b-table
      v-else
      style="width: 100%; table-layout: fixed;"
      ref="option-table"
      class="table-striped"
      :filter="filters"
      :fields="headers"
      :items="options"
      head-variant="light"
    >
      <template #cell(actions)="data">
        <memlist-table-button class="mr-3" @click="edit_option(data.item)" svg="/assets/svg/Write.svg" :tooltip="'Redigera'" />
        <memlist-table-button class="mr-3" @click="delete_option(data.item.id)" svg="/assets/svg/Trash.svg" :tooltip="'Radera'" />
      </template>
    </b-table>

    <QuestionFormQuestionItemOptionEditorModal
      ref="option-editor-modal"
      :option_id="selected_option_id"
      :form_item_id="item_id"

      @created="created"
      @updated="updated"
    />

  </div>
</template>

<script>
import axios from 'axios';
import QuestionFormQuestionItemOptionEditorModal from '@/view/pages/ml/questions/QuestionFormQuestionItemOptionEditorModal.vue';

export default {
  name: 'QuestionFormQuestionItemOptionTable',
  components: {
    QuestionFormQuestionItemOptionEditorModal
  },
  props: ['question_item_id'],
  data() {
    return {
      loading: true,
      selected_option_id: null,
      options: [],
      filters: {},
      total_rows: 0,
      current_page: 1,
      per_page: 10,
      search: null
    };
  },
  props: {
    item_id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    item_id: {
      handler(newVal) {

        if (this.item_id) {
          this.fetch_options(this.item_id);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    headers() {
      return [
        { label: 'ID', sortable: true, key: 'id', tdClass: 'td-short2', thClass: 'td-short2' },
        { label: 'Namn', sortable: true, key: 'name', tdClass: 'td-overflow', thClass: 'td-overflow' },
        { label: this.$t('COMMON.ACTIONS'), key: 'actions', sortable: false, }
      ];
    }
  },
  methods: {
    async fetch_options(id) {
      try {
        this.loading = true;
        const response = await axios.get(`/question_form/item/${id}/options`);
        this.options = response.data;
      } catch (error) {
        console.error("Error fetching options:", error);
      }

      this.loading = false;
    },

    edit_option(option) {
      this.selected_option_id = option.id;
      this.$refs['option-editor-modal'].show();
    },

    async delete_option(option_id) {
      if (!confirm('Är du säker på att du vill radera detta alternativ?')) return;

      try {
        await axios.delete(`/question_form/item/option/${option_id}`);
        this.options = this.options.filter(option => option.id !== option_id);
        
      } catch (error) {
        console.error("Error deleting option:", error);
      }
    },

    created(option) {
      this.options.push(option);
    },

    updated(option) {
      const index = this.options.findIndex(o => o.id === option.id);
      if (index !== -1) {
        this.options[index] = option;
      }
    },

    create_option() {
      this.selected_option_id = null;
      this.$refs['option-editor-modal'].reset();
      this.$refs['option-editor-modal'].show();
    },

    refresh() {
      if (this.item_id) {
        this.fetch_options(this.item_id);
      }
    }
  },
  mounted() {
  
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_table.scss";

.container {
  padding: 20px;
}
</style>
